import React, { useState, useEffect } from "react";
import $ from "jquery";
import "./Style.css";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import logo from "./images/logo.png";
import logowhite from "./images/logo-white.png";
import { AiOutlineAlignRight } from "react-icons/ai";
import { IoIosRefresh } from "react-icons/io";
import sun from "./images/sun.png";
import moon from "./images/moon.png";
import { AiFillSetting, AiFillWallet, AiOutlineLogout } from "react-icons/ai";
import config from "./config/config";

function Headernav() {
  const [walletAddress, setWalletAddress] = useState("");
  const [address, setAddress] = useState("");



  useEffect(() => {
    let walletAddress = localStorage.getItem("address");
    // console.log(walletAddress,"address========",address);
    if (walletAddress !== null) {
      setWalletAddress(
        walletAddress.substring(0, 5) +
        "..." +
        walletAddress.substring(walletAddress.length - 4)
      );
    }
    // console.log("walletAddress",walletAddress);
  }, [address]);


  const switchNetwork = async() => {
    try {
       let result = await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: config.chainID }],
        });
        console.log("result",result);
          window.location.reload();
      
    } catch (switchError) {
        // This error code indicates that the chain has not been added to MetaMask.
        if (switchError.code === 4902) {
            try {
              let resultAdd =  await window.ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [
                      {
                        chainId: config.chainID,
                        chainName:config.chainName,
                        rpcUrls: [config.goerliRpcURL],
                        blockExplorerUrls: [config.blockExplorerUrls],
                        nativeCurrency: {
                          name: "Ethereum LlamaNodes",
                          symbol: "ETH",
                          decimals: 18
                        },
                      },
                    ],
                });
                // console.log("resultAdd",resultAdd);
               
                  window.location.reload();// Set the flag to prevent further reloads
                
            } catch (addError) {
                // handle "add" error
                // console.log(addError)
            }
        }
        // handle other "switch" errors
    }

}

  useEffect(() => {
    // console.log(window.ethereum.networkVersion,"chainid");
    //  walletConnect();
    // if(window.ethereum.networkVersion != config.network){
    //   alert(window.ethereum.networkVersion)
    // }
    // console.log("walletAddress",walletAddress);
    //  switchNetwork()
    // walletConnect()
  }, []);

  const walletConnect = async () => {
    try {
      
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      // console.log("accounts", accounts[0]);
      localStorage.setItem("address", accounts[0]);
      let address = localStorage.getItem("address");
      setAddress(address);
      setWalletAddress(
        address.substring(0, 5) + "..." + address.substring(address.length - 4)
      );
      const chainId = await window.ethereum.request({ method: 'eth_chainId' });
      if(chainId != config.chainID){
      switchNetwork();
      }
    } catch (error) {
      console.log("connectWallet error", error);
    }
  };

  // const connectWallet = async () => {
  //   try {
  //     const accounts = await window.ethereum.request({
  //       method: "eth_requestAccounts",
  //     });
  //     console.log("accounts", accounts[0]);
  //     localStorage.setItem("address", accounts[0]);
  //     let address = localStorage.getItem("address");
  //     setAddress(address);
  //     setWalletAddress(
  //       address.substring(0, 5) + "..." + address.substring(address.length - 4)
  //     );
  //   } catch (error) {
  //     console.log("connectWallet error", error);
  //   }
  // };
  const logout = () => {
    setWalletAddress("");
    localStorage.clear();
  };

  return (
    <div className="top-navbar-new">
      <nav className="navbar navbar-expand-lg">
        <div className="container-fluid col-lg-11">
         <a href="/"><img src={logo} className="logo-img-css" /></a> 
          <button
            className="navbar-toggler text-white border"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <AiOutlineAlignRight className="fs-30" />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0 gap-3 mt-lg-0 mt-3">
              <li className="nav-item">
                {walletAddress !== "" ? (
                  <a class="nav-link active" className=" btn connect-button-styling-css">
                    {/* <span>
                                    <AiFillWallet />
                                </span> */}
                   
                      {" " + walletAddress}
                  
                  </a>
                ) : (
                  <a
                    class="nav-link active"
                    aria-current="page"
                    onClick={walletConnect}
                    className=" btn connect-button-styling-css"
                  >
                   
                      Connect Wallet
                  
                  </a>
                )}
              </li>
              <li className="nav-item">
                {walletAddress == ''?('') :(
                <a class="nav-link" onClick={logout} className=" btn connect-button-styling-css">
                   Disconnect <AiOutlineLogout className="ms-1" />
                </a>)
}
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Headernav;
