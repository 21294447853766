import { makeApiRequest } from "../config/axiosService";

  export const createFlashLoanHistory = async (bodyData) => {
    try {
      let params = {
        url: `createLoanHistory`,
        method: "POST",
        data: bodyData
      }
      let response = await makeApiRequest(params);
      return {
        status:response.status,
        message: response.message
      }
    } catch (error) {
      console.log('createFlashLoanHistory error',error);
      return {
        status:false,
        message: "Error on server"
      }
    }
  }

  export const getLoanHistory = async () => {
    try {
      let params = {
        url: `getLoanTransaction`,
        method: "GET"
      }
      let response = await makeApiRequest(params);
      // console.log('response',response)
      return {
        status:response.status,
        message: response.message,
        data:response.data
      }
    } catch (error) {
      console.log('getLoanHistory error',error);
      return {
        status:false,
        message: "Error on server"
      }
    }
  }

  export const getAdminFeeAmount = async () => {
    try {
      let params = {
        url: `getAdminFeeAmount`,
        method: "GET"
      }
      let response = await makeApiRequest(params);
      //  console.log('response',response)
      return {
        status:response.status,
        message: response.message,
        data:response.data
      }
    } catch (error) {
      console.log('getAdminFeeAmount error',error);
      return {
        status:false,
        message: "Error on server"
      }
    } 
  }