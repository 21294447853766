let config = {};

if (process.env.NODE_ENV === "development") {
  config = {
    Goerli_rpcURL:"https://mainnet.infura.io/v3/",
    contractAddress: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
    FRONTEND_URL: "http://localhost:3000/",
    BACKEND_URL: "http://localhost:5000/",
    flashLoan_contractAddress :"0x422F9a7B66C1d95FAA749A019ADb85405cE6235f",//"0xF588Bc924066Bc7AEDB449764f28693F47438853",//"0x9C26ba689B7dF3C15b351e1dEF31681Ad5f8C78f",//"0x9e5447aC5C2BfDf01A39406afb115824a42452dD",// old "0x9C26ba689B7dF3C15b351e1dEF31681Ad5f8C78f", 0xF588Bc924066Bc7AEDB449764f28693F47438853 -- recent
    network_symbol:"USDT",
    token_Address:"0x0B7a69d978DdA361Db5356D4Bd0206496aFbDD96", // AAVE token Address
    daiTokenAddress:"0x75Ab5AB1Eef154C0352Fc31D2428Cef80C7F8B33", //DAI token Address
    WBTC: "0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599",
    USDT: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
    sushiswapRouter:"0xd9e1cE17f2641f24aE83637ab66a2cca9C378B9F",
    uniswap:  "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
    Uniprice: "0x0DE0Fa91b6DbaB8c8503aAA2D1DFa91a192cB149",
    sushiprice: "0x784178D58b641a4FebF8D477a6ABd28504273132",

    explorerUrl:"https://goerli.etherscan.io/token/",
    goerliContractAddress : "0x5D8ec37503bECa60A7753bE947bCB82c849e2c24",
    aavetokenAddress:"0x0B7a69d978DdA361Db5356D4Bd0206496aFbDD96",
    sushiaddress:"0x5f1A06A0ff76935706f28F7C2bd85A1b23a85551",
    sushiSwapV2Router2:"0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506",
    //add or change network
    goerliRpcURL : "https://goerli.infura.io/v3/",
    chainID : "0x1",
    network: "1",
    chainName : "Goerli test network",
    symbol : "ETH",
    blockExplorerUrls : "https://goerli.etherscan.io",
    UniswapV2Router2: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
    transactionHashUrl: "https://goerli.etherscan.io/tx/",
    infuraKey:"https://mainnet.infura.io/v3/093cf180272947989f95a835644d5999",
    bkcUrl:"https://bkcscan.io/tx/"
  };
} else if (process.env.NODE_ENV === "Production") {
  config = {
    Goerli_rpcURL:"https://mainnet.infura.io/v3/",
    contractAddress: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
    FRONTEND_URL: "https://flashloan.valobitfl.com/",
    BACKEND_URL: "https://api-flashloan.valobitfl.com/",
    flashLoan_contractAddress :"0x422F9a7B66C1d95FAA749A019ADb85405cE6235f",//"0xF588Bc924066Bc7AEDB449764f28693F47438853",//"0x9C26ba689B7dF3C15b351e1dEF31681Ad5f8C78f",//"0x9e5447aC5C2BfDf01A39406afb115824a42452dD",// old "0x9C26ba689B7dF3C15b351e1dEF31681Ad5f8C78f", 0xF588Bc924066Bc7AEDB449764f28693F47438853 -- recent
    network_symbol:"USDT",
    token_Address:"0x0B7a69d978DdA361Db5356D4Bd0206496aFbDD96", // AAVE token Address
    daiTokenAddress:"0x75Ab5AB1Eef154C0352Fc31D2428Cef80C7F8B33", //DAI token Address
    WBTC: "0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599",
    USDT: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
    sushiswapRouter:"0xd9e1cE17f2641f24aE83637ab66a2cca9C378B9F",
    uniswap:  "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
    Uniprice: "0x0DE0Fa91b6DbaB8c8503aAA2D1DFa91a192cB149",
    sushiprice: "0x784178D58b641a4FebF8D477a6ABd28504273132",

    explorerUrl:"https://etherscan.io/token/",
    goerliContractAddress : "0x5D8ec37503bECa60A7753bE947bCB82c849e2c24",
    aavetokenAddress:"0x0B7a69d978DdA361Db5356D4Bd0206496aFbDD96",
    sushiaddress:"0x5f1A06A0ff76935706f28F7C2bd85A1b23a85551",
    sushiSwapV2Router2:"0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506",
    //add or change network
    goerliRpcURL : "https://mainnet.infura.io/v3/",
    chainID : "0x1",
    network: "1",
    chainName : "Goerli test network",
    symbol : "GoerliETH",
    blockExplorerUrls : "https://etherscan.io",
    UniswapV2Router2: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
    transactionHashUrl: "https://etherscan.io/tx/",
    infuraKey:"https://mainnet.infura.io/v3/093cf180272947989f95a835644d5999",
    bkcUrl:"https://valobitscan.io/tx/"
  };
} else {
  config = {
    Goerli_rpcURL:"https://mainnet.infura.io/v3/",
    contractAddress: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
    FRONTEND_URL: "https://flashloan.valobitfl.com/",
    BACKEND_URL: "https://api-flashloan.valobitfl.com/",
    flashLoan_contractAddress :"0x422F9a7B66C1d95FAA749A019ADb85405cE6235f",//"0xF588Bc924066Bc7AEDB449764f28693F47438853",//"0x9C26ba689B7dF3C15b351e1dEF31681Ad5f8C78f",//"0x9e5447aC5C2BfDf01A39406afb115824a42452dD",// old "0x9C26ba689B7dF3C15b351e1dEF31681Ad5f8C78f", 0xF588Bc924066Bc7AEDB449764f28693F47438853 -- recent
    network_symbol:"USDT",
    token_Address:"0x0B7a69d978DdA361Db5356D4Bd0206496aFbDD96", // AAVE token Address
    daiTokenAddress:"0x75Ab5AB1Eef154C0352Fc31D2428Cef80C7F8B33", //DAI token Address
    WBTC: "0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599",
    USDT: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
    sushiswapRouter:"0xd9e1cE17f2641f24aE83637ab66a2cca9C378B9F",
    uniswap:  "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
    Uniprice: "0x0DE0Fa91b6DbaB8c8503aAA2D1DFa91a192cB149",
    sushiprice: "0x784178D58b641a4FebF8D477a6ABd28504273132",

    explorerUrl:"https://etherscan.io/token/",
    goerliContractAddress : "0x5D8ec37503bECa60A7753bE947bCB82c849e2c24",
    aavetokenAddress:"0x0B7a69d978DdA361Db5356D4Bd0206496aFbDD96",
    sushiaddress:"0x5f1A06A0ff76935706f28F7C2bd85A1b23a85551",
    sushiSwapV2Router2:"0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506",
    //add or change network
    goerliRpcURL : "https://mainnet.infura.io/v3/",
    chainID : "0x1",
    network: "1",
    chainName : "Goerli test network",
    symbol : "GoerliETH",
    blockExplorerUrls : "https://etherscan.io",
    UniswapV2Router2: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
    transactionHashUrl: "https://etherscan.io/tx/",
    infuraKey:"https://mainnet.infura.io/v3/093cf180272947989f95a835644d5999",
    bkcUrl:"https://valobitscan.io/tx/"
  };
}
export default config;

