import React, { useEffect, useState } from "react";
import "./Style.css";
import Headernav from "./HeaderNav.js";
import { IoMdFlash } from "react-icons/io";
import { MdWifiFind } from "react-icons/md";
import config from "./config/config";
import { getLoanHistory } from "./backend_api/authapis";
import moment from "moment";
import aaveLogo from "./images/aave-logo.png";
import DataTable from "react-data-table-component";
import vusdlogo from './images/VUSD.png'

function App() {
  const [loanHistoryData, setLoanHistoryData] = useState([]);
  useEffect(() => {
    loanHistory();
  }, []);

  const columns = [
    {
      name: "Date",
      width: "200px",
      selector: (row) => {
        return (
          <div>{moment.utc(row.createdAt).format("YYYY-MM-DD hh:mm:ss")}</div>
        );
      },
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
    },
    {
      name:"Rewards",
      selector:(row)=> {
        const reward = row.profitAmount
        if(reward){
          return(`${reward}VUSD`)
        }
        return '-'
        }
      },
    
    {
      name: "Hash",
      selector: (row) => {
        return (
          <a
            href={config.transactionHashUrl + row.transactionHash}
            target="_blank"
            className="blue-color"
          >
            {row.transactionHash.substring(0, 5) +
              "..." +
              row.transactionHash.substring(row.transactionHash.length - 4)}
          </a>
        );
      },
    },
    {
      name: "rewardHash",
      selector: (row) => {
        const transactionHash = row.rewardHash;
        // console.log(row)
        if (transactionHash) {
          return (
            <a
              href={config.bkcUrl + transactionHash}
              target="_blank"
              className="blue-color"
            >
              {transactionHash.substring(0, 5) +
                "..." +
                transactionHash.substring(transactionHash.length - 4)}
            </a>
          );
        }
        return "-"; // Handle the case where transactionHash is undefined
      },
    },
    {
      name: "Asset",
      selector: (row) => {
        return (
          <>
            <img
              className="new-trans-assest-image"
              src={vusdlogo}
              alt="logo"
            />{" "}
            VUSD
          </>
        );
      },
    },
    {
      name: "Protocol",
      selector: (row) => {
        return (
          <div>
            <img className="new-trans-assest-image" src={aaveLogo} alt="logo" />{" "}
            AAVE V2
          </div>
        );
      },
    },
  ];

  const loanHistory = async () => {
    try {
      let loanHistory = await getLoanHistory();
      if (loanHistory.status) {
        setLoanHistoryData(loanHistory.data);
      } else {
        // console.log("Something went wrong");
      }
    } catch (error) {
      // console.log("getLoanHistory error", error);
    }
  };
  return (
    <div>
      <Headernav />
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-lg-4 ">
            <div className="sidenav-column-section">
              <div className="d-flex flex-row justify-content-center text-center align-items-center">
                <a href="/" className="text-white">
                  <div className="mx-3">
                    <div className="box-icon-styling-css">
                      <IoMdFlash />
                    </div>
                    <p className="fs-10">FlashGap</p>
                  </div>
                </a>
              </div>
              <div className="pb-1">
                <div className="mt-3">
                  <p className="mb-1 fs-13 fw-500 ">Chain Selection</p>
                  <p className="fs-13">Ethereum</p>
                </div>
              </div>
              <div className="pt-4 mb-5 border-top">
                <div className="mb-4">
                  <p className="mb-1 fs-13 fw-500">Token Symbol</p>
                  <p className="fs-13 border-bottom">{config.network_symbol}</p>
                </div>
                <div className="mb-4">
                  <p className="mb-1 fs-13 fw-500">Token Address</p>
                  <div className="border-bottom">
                    <a
                      className="fs-13 blue-color "
                      target="_blank"
                      href={`${config.explorerUrl}${config.USDT}`}
                    >
                      {config.USDT}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-7 pt-3">
            <div className=" py-5 new-trans-page-design sidenav-column-section-2">
              <h4 className="mb-4">Transactions</h4>
              <div className="card">
                <DataTable
                  columns={columns}
                  data={loanHistoryData}
                  pagination
                  paginationPerPage={10}
                  paginationRowsPerPageOptions={[10, 20, 30]}
                />
                {/* <div className="table-trans-height">
                  {/* <table class="table text-white tableFixHead">
                    <thead>
                      <tr>
                        <th scope="col">Time</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Hash</th>
                        <th scope="col">Asset</th>
                        <th scope="col">Protocol</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loanHistoryData.length > 0 ? loanHistoryData.map((data)=>{
                        return(
                          <>
                            <tr>
                              <td>{moment.utc(data.createdAt).format("YYYY-MM-DD hh:mm:ss")}</td>
                              <td>{data.amount}</td>
                              <td><a href={config.transactionHashUrl + data.transactionHash} target="_blank" className="blue-color">{
                                data.transactionHash.substring(0, 5) + "..." + data.transactionHash.substring(data.transactionHash.length - 4)
                              }</a></td>
                              <td><img className="new-trans-assest-image" src="https://storage.googleapis.com/eigenphi-token-icons/ethereum/0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48.png" alt="logo" /> VUSD</td>
                              <td><img className="new-trans-assest-image" src={aaveLogo} alt="logo" /> AAVE V2</td>
                            </tr>
                          </>
                        )
                      }) : (
                          <>
                            <tr>
                              <td align="center" colspan="5">There is no data is here</td>
                            </tr>
                          </>
                      )}
                    </tbody>
                  </table> 
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
